.App{
    
  margin: 0px;
  padding: 0px;
  background-color: white;
 
}
.yop{
  
  margin: 0px;
  padding: 0px;
  background-color:rgba(6, 6, 6, .2);
}
div div img.upo{
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 33%;
}
nav{
  
  background: rgb(78, 73, 83);
  justify-content: space-around;
  align-items: center;
  min-height:  10vh;
  display: flex;
  
}
.navli{
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  text-decoration: none;

}
.Link{
  text-decoration: none;
}





